import { Controller } from "@hotwired/stimulus"
import AOS from "aos"

export default class extends Controller {
  connect() {
    // FORCE REFRESH
    refreshFsLightbox();

    // INITIALIZATION OF SHOW ANIMATIONS
    // =======================================================
    new HSShowAnimation('.js-animation-link')

    // INITIALIZATION OF GO TO
    // =======================================================
    new HSGoTo('.js-go-to')

    // INITIALIZATION OF MEGA MENU
    // =======================================================
    new HSMegaMenu('.js-mega-menu')

    // INITIALIZATION OF TOGGLE SWITCH
    // =======================================================
    new HSToggleSwitch('.js-toggle-switch')

    // INITIALIZATION OF AOS
    // =======================================================
    AOS.init({
      duration: 650,
      once: true
    });
  }
}
